export function formatByThousands(number) {
  if (number === undefined || number === null) {
    console.warn("Number does not exist!");
    return null;
  }
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

export function capitalizeFirstLetter(string = "") {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function shuffleArray(array) {
  const shuffled = [...array];
  for (let i = shuffled.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
  }
  return shuffled;
}

export function getInitials(name) {
  // Trim the name and split it into words
  const words = name.trim().split(/\s+/);

  // If the name is empty, return an empty string
  if (words.length === 0) return "";

  // Get the first letter of each word and join them
  const initials = words.map((word) => word[0]).join("");

  // Return the initials in uppercase
  return initials.toUpperCase();
}
