import React, { useState, useMemo, useEffect } from "react";
import { FaSearch } from "react-icons/fa";
import { RiMotorbikeFill } from "react-icons/ri";
import { useQuery } from "react-query";
import ProductCard from "../common/ProductCard/ProductCard";
import ProductCardSkeleton from "../common/ProductCard/ProductCardSkeleton";
import SectionGradient from "../common/SectionGradient/SectionGradient";
import styles from "./Bikes.module.scss";
import QUERIES from "../../constants/queries";
import { IoStorefront } from "react-icons/io5";
import { FaRegFaceSadTear } from "react-icons/fa6";

const filters = {
  ALL: "ALL",
  BIKES: "BIKES",
  ARTICLES: "ARTICLES",
};

const Bikes = () => {
  const [products, setProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const [filter, setFilter] = useState(filters.ALL);

  const fetchProducts = async () => {
    const response = await fetch(
      "https://webapplication120240725104935.azurewebsites.net/api/Motorcycle/GetAll"
    );
    if (!response.ok) {
      throw new Error("Error fetching");
    }
    return response.json();
  };

  const { isFetching, error, isFetched } = useQuery(
    QUERIES.FETCH_PRODUCTS,
    fetchProducts,
    {
      onSuccess: (data) => {
        setProducts(data);
      },
    }
  );

  const filteredProducts = useMemo(() => {
    return products.filter((product) => {
      const searchString = searchTerm.toLowerCase();
      const matchesSearch =
        product.name.toLowerCase().includes(searchString) ||
        product.category.toLowerCase().includes(searchString) ||
        product.description.toLowerCase().includes(searchString);

      // New filter logic
      const matchesFilter =
        filter === filters.ALL ||
        (filter === filters.BIKES && !product.isArticle) ||
        (filter === filters.ARTICLES && product.isArticle);

      return matchesSearch && matchesFilter;
    });
  }, [products, searchTerm, filter]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const noProducts = isFetched && filteredProducts.length === 0;

  return (
    <div className={styles.bikes}>
      <SectionGradient />
      <div className={`wrapper ${styles.bikes_content}`}>
        <div className={styles.header}>
          <div className={styles.header_left}>
            <h1 className={`page_title ${styles.title}`}>
              <IoStorefront className={`page_title_icon`} />
              Productos
            </h1>

            <div className={styles.selector}>
              <button
                className={filter === filters.ALL ? styles.active : ""}
                onClick={() => setFilter(filters.ALL)}
              >
                Todos
              </button>
              <button
                className={filter === filters.BIKES ? styles.active : ""}
                onClick={() => setFilter(filters.BIKES)}
              >
                Motos
              </button>
              <button
                className={filter === filters.ARTICLES ? styles.active : ""}
                onClick={() => setFilter(filters.ARTICLES)}
              >
                Productos
              </button>
            </div>
          </div>

          <div className={styles.search}>
            <FaSearch />
            <input
              type="text"
              placeholder="Buscá el producto..."
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </div>
        </div>

        <div
          className={`${styles.products} ${
            noProducts ? styles.noProducts : ""
          }`}
        >
          {isFetching ? (
            <>
              <ProductCardSkeleton />
              <ProductCardSkeleton />
              <ProductCardSkeleton />
              <ProductCardSkeleton />
              <ProductCardSkeleton />
              <ProductCardSkeleton />
              <ProductCardSkeleton />
              <ProductCardSkeleton />
            </>
          ) : (
            filteredProducts.map((product) => (
              <ProductCard key={product.id} product={product} />
            ))
          )}

          {noProducts && (
            <div className={styles.notFound}>
              <FaRegFaceSadTear />
              <p>No se encontraron productos.</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Bikes;
