import React from "react";
import { BsImages } from "react-icons/bs";
import styles from "./Bike.module.scss";

const BikeSkeleton = () => {
  return (
    <div className={styles.product}>
      <div className={`${styles.slider_mock} skeleton dark`}>
        <BsImages />
      </div>
      <div className={`${styles.data_mock} skeleton dark`}>
        <div className={styles.data_mock_title}></div>

        <div className={styles.data_mock_text} style={{ width: "30%" }}></div>
        <div className={styles.data_mock_text} style={{ width: "80%" }}></div>
        <div className={styles.data_mock_text} style={{ width: "60%" }}></div>
        <div className={styles.data_mock_text} style={{ width: "15%" }}></div>

        <div className={styles.data_mock_price}></div>
      </div>
    </div>
  );
};

export default BikeSkeleton;
