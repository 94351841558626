import React from "react";
import styles from "./Footer.module.scss";
import { Link } from "react-router-dom";
import Logo from "../../assets/logo_new_dark.png";
import routes from "../../constants/routes";
import {
  FaClock,
  FaInstagram,
  FaMapMarkerAlt,
  FaPhone,
  FaWhatsapp,
} from "react-icons/fa";

const Footer = () => {
  return (
    <div className={styles.footer}>
      <div className={`wrapper ${styles.wrapper}`}>
        {/* Logo */}
        <div className={styles.section}>
          <Link to="/">
            <img className={styles.logo} src={Logo} alt="" />
          </Link>
          <span className={styles.rights_reserved}>
            Todos los derechos reservados © {new Date().getFullYear()}
          </span>
        </div>
        {/* Menu Links */}
        <div className={styles.section}>
          <ul className={`${styles.list} ${styles.links}`}>
            <li>
              <Link to={routes.us.path}>Nosotros</Link>
            </li>
            <li>
              <Link to={routes.services.path}>Servicios</Link>
            </li>
            <li>
              <Link to={routes.ourWorks.path}>Trabajos</Link>
            </li>
            <li>
              <Link to={routes.products.path}>Tienda</Link>
            </li>
            <li>
              <Link to={routes.contact.path}>Contacto</Link>
            </li>
          </ul>
        </div>
        {/* Info contacto */}
        <div>
          <ul className={`${styles.list} ${styles.info}`}>
            <li>
              <FaMapMarkerAlt />
              <span>
                Crisólogo Larralde 3060, Nuñez, Buenos Aires <br />
                <b>(solo con turno previo)</b>
              </span>
            </li>
            <li>
              <FaPhone />
              <span>11 5637 2706</span>
            </li>
            <li>
              <FaClock />
              <span>Lunes a viernes de 8:30 a 17:30 hs</span>
            </li>
          </ul>

          <div className={styles.socials}>
            <a
              href="https://www.instagram.com/motoasesor"
              target="_blank"
              rel="noreferrer"
            >
              <FaInstagram />
            </a>
            <a
              href="https://api.whatsapp.com/send?phone=541156372706"
              target="_blank"
              rel="noreferrer"
            >
              <FaWhatsapp />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
