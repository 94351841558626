import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Bike from "./components/Bike/Bike";
import Bikes from "./components/Bikes/Bikes";
import Header from "./components/Header/Header";
import Home from "./components/Home/Home";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./App.scss";
import "./Admin.scss";
import Footer from "./components/Footer/Footer";
import ContactUs from "./components/ContactUs/ContactUs";
import { FaWhatsapp } from "react-icons/fa";
import Us from "./components/Us/Us";
import Services from "./components/Services/Services";
import Jobs from "./components/Jobs/Jobs";
import routes from "./constants/routes";
import Products from "./components/Admin/Products/Products/Products";
import ProductForm from "./components/Admin/Products/ProductForm/ProductForm";
import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import PrivateRoute from "./routing/PrivateRoute";
import PublicRoute from "./routing/PublicRoute";
import Login from "./components/Admin/Login/Login";
import AdminRouter from "./components/Admin/AdminRouter";

function App() {
  const queryClient = new QueryClient();

  const darkTheme = createTheme({
    palette: {
      mode: "dark",
    },
  });

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        <BrowserRouter>
          <Routes>
            <Route
              path={`${routes.adminBase.path}/*`}
              element={<AdminRouter />}
            />

            <Route
              path="*"
              element={
                <>
                  <Header />
                  <div className="content">
                    <a
                      href="https://api.whatsapp.com/send?phone=541156372706"
                      target="_blank"
                      rel="noreferrer"
                      className="floating_wsp"
                    >
                      <FaWhatsapp />
                    </a>
                    <Routes>
                      <Route path="/" element={<Home />} />
                      <Route path={routes.products.path} element={<Bikes />} />
                      <Route
                        path={routes.products.dynamicPath}
                        element={<Bike />}
                      />
                      <Route
                        path={routes.contact.path}
                        element={<ContactUs />}
                      />
                      <Route path={routes.us.path} element={<Us />} />
                      <Route
                        path={routes.services.path}
                        element={<Services />}
                      />
                      <Route path={routes.ourWorks.path} element={<Jobs />} />
                      <Route path="*" element={<div>Not found!</div>} />
                    </Routes>
                  </div>
                  <Footer />
                </>
              }
            />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
