import styles from "./ProductCard.module.scss";

const ProductCardSkeleton = () => (
  <div className={styles.skeletonCard}>
    <div className={`${styles.skeletonImage} skeleton`}></div>
    <div className={styles.skeletonContent}>
      <div className={`${styles.skeletonTitle} skeleton`}></div>
      <div className={`${styles.skeletonPrice} skeleton`}></div>
    </div>
  </div>
);

export default ProductCardSkeleton;
