import { Button } from "@mui/material";
import React, { useState } from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { IoStorefront } from "react-icons/io5";
import { TbTrash } from "react-icons/tb";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import QUERIES from "../../../../constants/queries";
import routes from "../../../../constants/routes";
import useStore from "../../../../store/store";
import { formatByThousands } from "../../../../utils";
import Sidebar from "../../Sidebar/Sidebar";
import styles from "./Products.module.scss";

const Products = () => {
  const [products, setProducts] = useState([]);
  const open = useStore((store) => store.sidebarOpen);

  const fetchProducts = async () => {
    const response = await fetch(
      "https://webapplication120240725104935.azurewebsites.net/api/Motorcycle/GetAll"
    );
    if (!response.ok) {
      throw new Error("Error fetching");
    }
    return response.json();
  };

  const { isFetching, error } = useQuery(
    QUERIES.FETCH_PRODUCTS_ADMIN,
    fetchProducts,
    {
      onSuccess: (data) => {
        setProducts(data);
      },
      refetchOnWindowFocus: false,
    }
  );

  const queryClient = useQueryClient();

  const deleteProduct = async (id) => {
    await fetch(
      `https://webapplication120240725104935.azurewebsites.net/api/Motorcycle/Delete?id=${id}`,
      {
        method: "DELETE",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  };

  const deleteMutation = useMutation(deleteProduct, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERIES.FETCH_PRODUCTS_ADMIN);
    },
    onError: () => {
      Swal.fire({
        title: "Error eliminando producto",
        text: "Por favor intentalo nuevamente en unos minutos",
        icon: "error",
      });
    },
  });

  const handleDelete = (e, id) => {
    e.preventDefault();
    e.stopPropagation();

    Swal.fire({
      title: "¿Seguro que desea eliminar el producto?",
      text: "Esta acción no puede deshacerse",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#666",
      confirmButtonText: "Sí, eliminar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) deleteMutation.mutate(id);
    });
  };

  return (
    <div className={`admin ${!open ? "closed" : ""}`}>
      <Sidebar />
      <div className='admin_content'>
        <div className='admin_header'>
          <h1>
            <IoStorefront />
            Productos
          </h1>

          <div>
            <Link to={routes.adminProducts.new.path}>
              <Button variant='contained'>Cargar producto</Button>
            </Link>
          </div>
        </div>

        <div className='admin_data'>
          {isFetching ? (
            <div className='admin_loading'>
              <AiOutlineLoading3Quarters />
            </div>
          ) : (
            <div className={styles.products}>
              {products.map((product) => (
                <Link
                  to={`${routes.adminProducts.list.path}/${product.id}`}
                  className={styles.product}
                  key={product.id}
                >
                  <button
                    className={styles.deleteButton}
                    onClick={(e) => handleDelete(e, product.id)}
                  >
                    <TbTrash />
                  </button>
                  <img
                    className={styles.img}
                    src={product.urlFiles?.[0]}
                    alt={product.name}
                    height={200}
                  />
                  <div className={styles.data}>
                    <p className={styles.title}>{product.name}</p>

                    {!product.isArticle && (
                      <p className={styles.metadata}>
                        {product.year}{" "}
                        <span className={styles.separator}>|</span>{" "}
                        {product.kilometres === 0
                          ? "0 km"
                          : `${formatByThousands(product.kilometres)} km`}
                      </p>
                    )}
                    <p className={styles.price}>
                      {product.isDollarPrice ? "U$S" : "$"}{" "}
                      {formatByThousands(product.price)}
                    </p>
                  </div>
                </Link>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Products;
