import React from "react";
import HeroImg from "../../../assets/hero.jpg";
import styles from "./SectionGradient.module.scss";

const SectionGradient = () => {
  return (
    <div className={styles.gradient}>
      <div
        className={styles.bg}
        style={{ backgroundImage: `url(${HeroImg})` }}
      ></div>
    </div>
  );
};

export default SectionGradient;
