import React, { useEffect, useState } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import routes from "../../constants/routes";
import PrivateRoute from "../../routing/PrivateRoute";
import PublicRoute from "../../routing/PublicRoute";
import Products from "./Products/Products/Products";
import Jobs from "./Jobs/Jobs/Jobs";
import ProductForm from "./Products/ProductForm/ProductForm";
import JobForm from "./Jobs/JobForm/JobForm";
import Login from "./Login/Login";
import useStore from "../../store/store";
import { adminRoute } from "./utils";

const AdminRouter = () => {
  const [authCheckDone, setAuthCheckDone] = useState(false);
  const [showError, setShowError] = useState(false);

  const setUser = useStore((state) => state.setUser);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const response = await fetch(
          "https://webapplication120240725104935.azurewebsites.net/api/Auth/authorize",
          {
            credentials: "include",
          }
        );

        if (!response.ok) throw new Error(response.status);

        const data = await response.json();

        setUser({
          id: data?.id,
          email: data?.email,
          full_name: data?.fullName,
        });

        setAuthCheckDone(true);
      } catch (err) {
        if (err.toString().includes("401")) {
          setAuthCheckDone(true);
        } else {
          setShowError(true);
          setAuthCheckDone(true);
        }
      }
    };

    checkAuth();
  }, [setUser]);

  if (showError)
    return <div>Error del servidor. Por favor contacte a soporte.</div>;
  if (!authCheckDone) return null;

  return (
    <Routes>
      <Route path="/" element={<Navigate to={routes.adminLogin.path} />} />
      {/* Products routes */}
      <Route
        element={<PrivateRoute outlet={Products} />}
        path={adminRoute(routes.adminProducts.list.path)}
      />
      <Route
        element={<PrivateRoute outlet={ProductForm} />}
        path={adminRoute(routes.adminProducts.new.path)}
      />
      <Route
        element={<PrivateRoute outlet={ProductForm} />}
        path={`${adminRoute(routes.adminProducts.list.path)}/:id`}
      />

      {/* Jobs routes */}
      <Route
        element={<PrivateRoute outlet={Jobs} />}
        path={adminRoute(routes.adminJobs.list.path)}
      />

      <Route
        element={<PrivateRoute outlet={JobForm} />}
        path={adminRoute(routes.adminJobs.new.path)}
      />

      <Route
        element={<PrivateRoute outlet={JobForm} />}
        path={`${adminRoute(routes.adminJobs.list.path)}/:id`}
      />

      {/* Auth routes */}
      <Route
        element={<PublicRoute outlet={Login} />}
        path={adminRoute(routes.adminLogin.path)}
      />
    </Routes>
  );
};

export default AdminRouter;
