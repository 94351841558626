import React, { useEffect, useState } from "react";
import Sidebar from "../../Sidebar/Sidebar";
import { RiMotorbikeFill } from "react-icons/ri";
import "react-quill/dist/quill.snow.css";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";
import QUERIES from "../../../../constants/queries";
import styles from "./ProductForm.module.scss";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { formatByThousands } from "../../../../utils";
import { Link, useNavigate, useParams } from "react-router-dom";
import routes from "../../../../constants/routes";
import { Controller, useForm } from "react-hook-form";
import { useDropzone } from "react-dropzone";
import ReactQuill from "react-quill";
import ReactInputMask from "react-input-mask";
import { IoStorefront } from "react-icons/io5";
import Swal from "sweetalert2";
import Pics from "../../common/Pics";

const photoErr = "Al menos tiene que haber una imagen.";

const currentYear = new Date().getFullYear();
const years = Array.from(
  { length: currentYear - 1899 },
  (_, i) => currentYear - i
);

const ProductForm = () => {
  const [photoError, setPhotoError] = useState("");
  const [editModePhotos, setEditModePhotos] = useState([]);
  const navigate = useNavigate();

  const { id } = useParams();

  const { control, handleSubmit, watch, setValue } = useForm({
    defaultValues: {
      title: "",
      price: "",
      category: "",
      year: currentYear,
      isZeroKm: false,
      kilometers: "",
      description: "",
      isDollarPrice: false,
      currency: "ARS",
      photos: [],
      isBike: true,
    },
  });

  /* If there's an id, call the query */
  const fetchProduct = async () => {
    const response = await fetch(
      `https://webapplication120240725104935.azurewebsites.net/api/Motorcycle/Get?id=${id}`
    );

    if (!response.ok) {
      const error = new Error("An error occurred while fetching the data.");
      error.status = response.status;
      throw error;
    }

    return response.json();
  };

  const { isFetching, error } = useQuery(
    [QUERIES.FETCH_PRODUCT_ADMIN],
    fetchProduct,
    {
      onSuccess: (data) => {
        setValue("title", data.name);
        setValue("isDollarPrice", data.isDollarPrice);
        setValue("price", data.price);
        setValue("category", data.category);
        setValue("year", data.year);
        setValue("isZeroKm", data.kilometres === 0);
        setValue("kilometers", data.kilometres);
        setValue("description", data.description);
        setValue("isBike", !data.isArticle);

        setEditModePhotos(data.urlFiles);
      },
      onError: (error) => {
        navigate(routes.adminProducts.list.path);
      },
      refetchOnWindowFocus: false,
      enabled: typeof id === "string" && !!id,
      retry: (failureCount, error) => {
        if (error.status === 404) return false;
        return failureCount < 1;
      },
    }
  );

  const [photos, setPhotos] = useState([]);

  const isZeroKm = watch("isZeroKm");
  const isBike = watch("isBike");

  const uploadFiles = async ({ id, files }) => {
    const formData = new FormData();
    files.forEach((file, index) => {
      formData.append("files", file);
    });

    const response = await fetch(
      `https://webapplication120240725104935.azurewebsites.net/api/Motorcycle/UploadFile?motorcycleId=${id}`,
      {
        method: "POST",
        credentials: "include",
        body: formData,
      }
    );

    if (!response.ok) {
      throw new Error("File upload failed");
    }

    return true;
  };

  const uploadFilesMutation = useMutation(uploadFiles, {
    onSuccess: () => {
      Swal.fire({
        title: `¡Producto ${!!id ? "editado" : "creado"}!`,
        icon: "success",
      }).then(() => {
        navigate(routes.adminProducts.list.path);
      });
    },
    onError: () => {
      Swal.fire({
        title: "Error subiendo las fotos",
        text: "Por favor, edite el producto para intentar subir las fotos nuevamente",
        icon: "error",
      }).then(() => {
        navigate(routes.adminProducts.list.path);
      });
    },
  });

  const createEditProduct = async (product) => {
    console.log({ product });
    const response = await fetch(
      `https://webapplication120240725104935.azurewebsites.net/api/Motorcycle/${
        !!id ? "Update" : "Create"
      }`,
      {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(product),
      }
    );

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return response.json();
  };

  const createMutation = useMutation(createEditProduct, {
    onSuccess: (data) => {
      const id = data.id;
      uploadFilesMutation.mutate({ id, files: photos });
    },
    onError: () => {
      Swal.fire({
        title: "Error cargando el producto",
        text: "Por favor intentalo nuevamente en unos minutos",
        icon: "error",
      });
    },
  });

  const editMutation = useMutation(createEditProduct, {
    onSuccess: (data) => {
      const id = data.id;
      if (photos.length > 0) {
        uploadFilesMutation.mutate({ id, files: photos });
      } else {
        Swal.fire({
          title: "¡Producto editado!",
          icon: "success",
        }).then(() => {
          navigate(routes.adminProducts.list.path);
        });
      }
    },
    onError: () => {
      Swal.fire({
        title: "Error editando el producto el producto",
        text: "Por favor intentalo nuevamente en unos minutos",
        icon: "error",
      });
    },
  });

  const onSubmit = (data) => {
    if (
      (photos.length === 0 && !id) ||
      (!!id && photos.length === 0 && editModePhotos.length === 0)
    ) {
      setPhotoError(photoErr);
      return;
    }

    const body = {
      name: data.title,
      price: parseInt(data.price.toString().replace(/\./g, "")),
      isDollarPrice: data.isDollarPrice,
      category: data.category,
      year: !data.isBike ? 0 : data.year,
      kilometres: !data.isBike
        ? 0
        : data.isZeroKm
        ? 0
        : parseInt(data.kilometers),
      description: data.description,
      photos: data.photos,
    };

    if (!!id) {
      body.id = id;
      body.urlFiles = editModePhotos;

      editMutation.mutate(body);
    } else {
      body.id = "";
      body.isArticle = !data.isBike;
      body.urlFiles = [];

      createMutation.mutate(body);
    }
  };

  const formatPrice = (value) => {
    const cleanValue = value.replace(/\D/g, "");
    return cleanValue.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpg", ".jpeg"],
    },
    maxSize: 6 * 1024 * 1024, // 6MB
    maxFiles: 10,
    onDrop: (acceptedFiles) => {
      const newPhotos = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );
      setPhotos((prevPhotos) => [...prevPhotos, ...newPhotos]);
      setValue("photos", [...photos, ...newPhotos]);
      setPhotoError("");
    },
  });

  // Clean up object URLs to avoid memory leaks
  useEffect(() => {
    return () => photos.forEach((photo) => URL.revokeObjectURL(photo.preview));
  }, [photos]);

  const removeExistentPhoto = async ({ url }) => {
    await fetch(
      `https://webapplication120240725104935.azurewebsites.net/api/Motorcycle/DeleteFile?fileUrl=${url}&motorcycleId=${id}`,
      {
        method: "POST",
        credentials: "include",
      }
    );
  };

  const queryClient = useQueryClient();

  const deletePhotoMutation = useMutation(removeExistentPhoto, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERIES.FETCH_PRODUCT_ADMIN);
    },
    onError: () => {
      Swal.fire({
        title: "Error al eliminar la foto",
        text: "Por favor intente nuevamente",
        icon: "error",
      });
    },
  });

  const removePhoto = (index) => {
    setPhotos((prevPhotos) => {
      const newPhotos = [...prevPhotos];
      URL.revokeObjectURL(newPhotos[index].preview);
      newPhotos.splice(index, 1);
      setValue("photos", newPhotos);

      if (
        (photos.length === 0 && !id) ||
        (!!id && photos.length === 0 && editModePhotos.length === 0)
      ) {
        setPhotoError(photoErr);
      }

      return newPhotos;
    });
  };

  const isLoading =
    createMutation.isLoading ||
    uploadFilesMutation.isLoading ||
    deletePhotoMutation.isLoading ||
    editMutation.isLoading ||
    isFetching;

  return (
    <div className="admin">
      <Sidebar />
      <div className="admin_content">
        <div className="admin_header">
          <h1>
            <IoStorefront />
            {id ? "Editar producto" : "Cargar producto"}
          </h1>

          <div>
            <Link to={routes.adminProducts.list.path}>
              <Button variant="contained">Volver atrás</Button>
            </Link>
          </div>
        </div>

        <div className="admin_data">
          {isLoading && (
            <div className="admin_loading_overlay">
              <div className="admin_loading_overlay_loader">
                <AiOutlineLoading3Quarters />
              </div>
            </div>
          )}
          <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
            {!id && (
              <Controller
                name="isBike"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={<Switch checked={field.value} {...field} />}
                    label="¿Es moto?"
                  />
                )}
              />
            )}

            <Controller
              name="title"
              control={control}
              rules={{ required: true, minLength: 4, maxLength: 255 }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  label="Título"
                  fullWidth
                  margin="normal"
                  variant="filled"
                  error={!!error}
                  helperText={
                    error
                      ? "El título no puede estar vacío (4-255 caractéres)"
                      : ""
                  }
                />
              )}
            />

            <InputLabel>Moneda</InputLabel>
            <div className={styles.currency}>
              <Typography variant="body2">ARS</Typography>
              <Controller
                name="isDollarPrice"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={<Switch checked={field.value} {...field} />}
                    label=""
                  />
                )}
              />
              <Typography variant="body2">USD</Typography>
            </div>

            <Controller
              name="price"
              control={control}
              rules={{ required: true, maxLength: 31 }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  label="Precio"
                  fullWidth
                  margin="normal"
                  variant="filled"
                  error={!!error}
                  helperText={
                    error ? "Price is required (max 31 characters)" : ""
                  }
                  onChange={(e) => {
                    const formatted = formatPrice(e.target.value);
                    field.onChange(formatted);
                  }}
                  inputProps={{
                    maxLength: 31,
                  }}
                />
              )}
            />

            <Controller
              name="category"
              control={control}
              rules={{ required: true, minLength: 4, maxLength: 127 }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  label="Categoría"
                  fullWidth
                  variant="filled"
                  margin="normal"
                  error={!!error}
                  helperText={
                    error ? "Category is required (4-127 characters)" : ""
                  }
                />
              )}
            />

            {isBike && (
              <>
                <Controller
                  name="year"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <FormControl fullWidth margin="normal" variant="filled">
                      <InputLabel>Año</InputLabel>
                      <Select {...field}>
                        {years.map((year) => (
                          <MenuItem key={year} value={year}>
                            {year}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />

                <Controller
                  name="isZeroKm"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={<Switch checked={field.value} {...field} />}
                      label="¿Es 0km?"
                    />
                  )}
                />

                {!isZeroKm && (
                  <Controller
                    name="kilometers"
                    control={control}
                    rules={{ required: true, min: 0, max: 4999999 }}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        label="Kilómetros"
                        type="number"
                        fullWidth
                        variant="filled"
                        margin="normal"
                        error={!!error}
                        helperText={
                          error ? "Kilometers are required (0-4999999)" : ""
                        }
                      />
                    )}
                  />
                )}
              </>
            )}

            <InputLabel>Descripción</InputLabel>
            <Controller
              name="description"
              control={control}
              rules={{ required: true, maxLength: 50000 }}
              render={({ field }) => (
                <ReactQuill
                  {...field}
                  theme="snow"
                  modules={{
                    toolbar: [
                      ["bold", "underline"],
                      [{ size: ["small", false, "large", "huge"] }],
                      [{ list: "ordered" }, { list: "bullet" }],
                      [{ align: [] }],
                    ],
                  }}
                  style={{ height: "200px", marginBottom: "50px" }}
                />
              )}
            />

            <InputLabel>Imágenes</InputLabel>
            <Box
              {...getRootProps()}
              style={{
                border: "2px dashed #ccc",
                padding: "20px",
                marginBottom: "20px",
                marginTop: "4px",
              }}
            >
              <input {...getInputProps()} />
              <Typography>
                Haz click aquí o arrastra para subir las fotos
              </Typography>
              <Typography variant="caption">
                Sólo se aceptan archivos *.jpg, *.jpeg o *.png de 6MB o menos
              </Typography>
            </Box>
            {photoError && (
              <Typography color="error" variant="body2">
                {photoError}
              </Typography>
            )}

            {/* New photos */}
            <Pics photos={photos} removePhoto={removePhoto} />

            {/* Existent photos */}
            <Pics
              photos={editModePhotos}
              removePhoto={deletePhotoMutation}
              existent
            />

            <Button
              type="submit"
              variant="contained"
              color="primary"
              style={{ marginTop: "20px" }}
              disabled={isLoading}
            >
              {!!id ? "Guardar cambios" : "Cargar producto"}
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ProductForm;
